import React, { useState, createRef, useEffect } from "react";
import Left_menu from './Left_menu'
import Header from './Header'
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import { useNetworkState } from 'react-use';
const Rate_package = () => {
    let navigate = useNavigate();
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[loadingstate,setLoadingstate] = React.useState({loading:false})
    const[errorstate,seterrorstate] = React.useState({message:""})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const [otherState, setOtherState] = useState({ submit_button: true });
    const location = useLocation();
  
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    
    //  // console.log("location==>",location)
    let  parsed = queryString.parse(location.search);
  // console.log("parsed",parsed);
  
  
    const [state, setState] = useState([]);
    let apiFlag = true;
  
    useEffect(() => {
      if(apiFlag){
        apiFlag = false;
      get_list()  
      dimension_detail()    
      }
  }, [])
  
  const dimension_detail = () =>{
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }
  
    const get_list = async (sortValue) => {
      setLoadingstate({...loadingstate,loading:true})
      
      let sort = "default";
      if(sortValue) {
        sort = sortValue
      }
      let dataToSend = {customer_id:userData.customer_id,
        from_postal_code:parsed.sender_postal_code ,
         to_postal_code:parsed.receiver_postal_code, 
         weight:parsed.weight,
         length:parsed.length,
         height:parsed.height,
         width:parsed.width,
         sort_by:sort ,
          parcel_type:parsed.shipment_type ,
           mode:parsed.mode
    };
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/shipment_rate_time';
     
     // // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((responseJson) => {
            // console.log("responseJson => ", responseJson.data);
            setLoadingstate({...loadingstate,loading:false})
            if(responseJson.data.status=="success"){
              setState(responseJson.data.output)
              if(responseJson.data.output.length==0){
                seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
              }
            }
  
            else{
            
            //  seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
            //  // console.log("message",responseJson.data.message);
             Swal.fire({
              icon: 'error',
              // title: 'Oops...',
              text: 'Something Went wrong',
              
            })
            }
             
          })
          .catch((error) => {
              //Hide Loader
              setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });
  };
  
  const sort_by = (e) =>{
  // console.log(e.target.value);
  get_list(e.target.value)
  }
  
  
    return (
      <div>
            {/* <?php include 'meta.php';?> */}
      
      {/* <?php include 'left_menu.php';?> */}
  <Left_menu/>
      <section class="home ">
          {/* <?php include 'header.php';?>  */}
  <Header/>
          
      <div class="container pt-3">
          <div class="row">
              <div class="col-12 col-md-12">
                  <div class="row">
                      <div class="col-12 col-md">
                          <h4>Select Courier Partner</h4>
                      </div>
                      <div class="col-12 col-md-4 text-end">
                         <small> Pickup From
                          <strong>{parsed.sender_postal_code}</strong> | 
                          Deliver To
                          <strong>{parsed.receiver_postal_code}</strong></small>
                      </div>
                  </div>
                  <div class="row pt-3 mb-3">
                      <div class="col-12 col-md">
                          <ul class="nav nav-pills">
                              <li class="nav-item">
                              <a class="nav-link active" href="#">All</a>
                              </li>
                              <li class="nav-item">
                              <a class="nav-link" href="#">Air</a>
                              </li>
                          </ul>
                      </div>
                      <div class="col-12 col-md-4">
                          <select class="form-select form-select-sm" onChange={(e)=>sort_by(e)}>
                          <option>Sort By: Recommended</option>
                          <option value="low">Sort By: Cheapest</option>
                          </select>
                      </div>
                  </div>
                  <section class="package_box">
                  {loadingstate.loading?
            <div style={{width:"100%", display: "flex",
    justifyContent: "center"}}>
  
      
          <ReactLoading type={"spin"} color={"#0d6efd"} height={300} width={89} />
          </div>
          :""}
           <h3>
              {errorstate.message}</h3>
                      <div class="card mb-3">
                      {(state.length != 0) ?
            state.map((item,index)=>
                        <div class="card-body">
                          <div class="row">
                              <div class="col-12 col-md">
                                  <div class="d-flex">
                                      <div class="p-2">
                                          <figure>
                                              <img src={`https://cdn.shipdartexpress.com/images/${item.carrierImage}`} class="img-fluid" />
                                          </figure>
                                      </div>
                                      <div class="ps-2 ">
                                          <h4>{item.service_provider}</h4>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 col-md-4 text-end">
                                  <h6>{item.product_type_name?item.product_type_name:""} 
                                  {/* | Min-weight: 0.5 kg */}
                                  </h6>
                              </div>
                          </div>
                         
                          <div class="row">
                              <div class="col-12 col-md-2">
                                  <div class="card rating_card">
                                    <div class="card-body">
                                      <h5>Rating 3.5</h5>
                                      <div class="text-warning">
                                          <i class="material-icons-outlined">star</i>
                                          <i class="material-icons-outlined">star</i>
                                          <i class="material-icons-outlined">star</i>
                                          <i class="material-icons-outlined">star_half</i>
                                          <i class="material-icons-outlined">star_border</i>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h5>Expected Pickup</h5>
                                  <small>-----</small>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h5>Estimated Delivery</h5>
                                  <small>----</small>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h5>Weight</h5>
                                  <small>-----</small>
                              </div>
                              <div class="col-12 col-md pt-3">
                                  <h3>{dimensionState.companyDetail.map((subscriber)=>( 
                                    <>{subscriber.set_currency}</>
                                   ))}{parseFloat(Number(item.total_charges)+Number(item.commission_charge)).toFixed(2)}</h3>
                              </div>
                              {/* {(otherState.submit_button) ? */}
                            
                                 
                              {/* :  <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />} */}
                          </div>
                          <div className="mt-3">
                          <hr/>
                          </div>
                        </div>
                        
                        ):""}
                      </div>
                   
                      
                    
                  </section>
              </div>
          </div>
      </div>
          
          
      </section>
  
  
      {/* <?php include 'footer.php';?> */}
      </div>
    )
  }

export default Rate_package
