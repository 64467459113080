import React, { useState, useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, CornerUpLeftIcon, FileDown, Truck } from 'lucide-react';

const Add_product = () => {

    let navigate = useNavigate()
 
    const [productName, setProductName] = useState('');
    const [category, setCategory] = useState('');
    const [variants, setVariants] = useState([{  value: '' }]);
    const [categories, setCategories] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    const [itemWeight, setItemWeight] = useState('');
    const [packageHeight, setPackageHeight] = useState('');
    const [packageWidth, setPackageWidth] = useState('');
    const [packageLength, setPackageLength] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [highStockThreshold, setHighStockThreshold] = useState('');
    const [lowStockThreshold, setLowStockThreshold] = useState('');
    const [sku, setSku] = useState('');
  
 const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    React.useEffect(() => {
        fetchCategories();
      }, []);
    
      const fetchCategories = async () => {
        try {
          const response = await axios.post(`${config.apiUrl}/user/category_list`, {}, {
            headers: config.headers 
          });
          if (response.data.status === true) {
            setCategories(response.data.output);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to fetch categories!',
            });
          }
        } catch (error) {
          console.error('Error fetching categories:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        }
      };
    
      const handleAddVariant = () => {
        setVariants([...variants, { value: '' }]);
      };
    
      const handleRemoveVariant = (index) => {
        const updatedVariants = [...variants];
        updatedVariants.splice(index, 1);
        setVariants(updatedVariants);
      };
    
      const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        setImageFile(selectedFile);
        const reader = new FileReader();
        reader.onload = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Validation checks
        const isVariantValueValid = variants.every(variant => variant.value && !/\s/.test(variant.value)); // Check if variant value contains any space
        const isVariantEmpty = variants.some(variant =>  !variant.value);
        if (!productName || !category || isVariantEmpty || !imageFile || !itemWeight || !packageHeight || !packageWidth || !packageLength || !itemPrice || !highStockThreshold || !lowStockThreshold || !sku) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'All fields are required!',
          });
          return;
        }

        if ( !isVariantValueValid) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Variant values cannot contain spaces!',
            });
            return;
          }
      
        // Additional validation checks for each field can be added here
      
        try {
          // Prepare form data including imageFile and send to backend...
          let formData = new FormData();
          formData.append('productName', productName);
          formData.append('category', category);
          formData.append('sku', sku);
          formData.append('imageFile', imageFile);
          formData.append('itemWeight', itemWeight);
          formData.append('packageHeight', packageHeight);
          formData.append('packageWidth', packageWidth);
          formData.append('packageLength', packageLength);
          formData.append('itemPrice', itemPrice);
          formData.append('highStockThreshold', highStockThreshold);
          formData.append('lowStockThreshold', lowStockThreshold);
          variants.forEach((variant, index) => {
            formData.append(`variants[${index}][value]`, variant.value);
          });
      
        
          // Send formData to backend API
          const response = await axios.post(`${config.apiUrl}/user/add_product`, formData, {
            headers: config.headers 
          });
          
      
          // Handle success response
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Product added successfully!',
          });
          navigate('/customer/product_list')
        } catch (error) {
          console.error('Error adding product:', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        }
      };
  return (
    <div>
       <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
       <div className="home-section" >
         
         <Header/>
    
           <div className="row mb-2">
               <div className="col-12 col-md">
                   <div className="d-flex">
                       <div className="back_btn_wrap">
                           <a href="" className="back_btn">
                           <CornerUpLeft/>
                           </a>
                       </div>
                       <div className="">
                           <h2>Add Product </h2>
                       </div>
                   </div>
               </div>
               <div className="col-12 col-md-5 text-end btns">
               </div>
           </div>
           

           <section className="mb-3 product_add_box pb-5">
               <div className="card border-0 shadow-sm ">
                 <div className="card-body">
                   <div className="row">
                       <div className="col-12 col-md-6">
                           <div className="row">
                               <div className="col-12 col-md mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                       <h4>Product Name</h4>
                                       <label for="email" class="form-label">Product Name: <span class="text-danger">*</span></label>
                                       <input type="text" className="form-control" id="productName" placeholder="Enter product name" required value={productName} onChange={(e) => setProductName(e.target.value)} />
                                     </div>
                                   </div>
                               </div>
                               <div className="col-12 col-md-12 mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                       <h4>Category</h4>
                                           <label htmlFor="category" className="form-label">Product Category: <span className="text-danger">*</span></label>
                                           <select className="form-select" id="category" required value={category} onChange={(e) => setCategory(e.target.value)}>
                                             <option value="">Select</option>
                                             {categories.map((sub) => (
                                               <option key={sub.category_id} value={sub.category_id}>{sub.category_name}</option>
                                             ))}
                                           </select>
                                     </div>
                                   </div>
                               </div>
                               <div className="col-12 col-md-12 mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                       <h4>SKU Information</h4>
                                       <label htmlFor="sku" className="form-label">SKU Code: <span className="text-danger">*</span></label>
                                       <input type="text" className="form-control" id="sku" placeholder="Enter product sku" required value={sku} onChange={(e) => setSku(e.target.value)} />
                                     </div>
                                   </div>
                               </div>
                               <div className="col-12 col-md-12 mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                       <h4>Variants <span className="text-danger">*</span></h4>
                                           <table className="table table-bordered table-responsive">
                                               <thead>
                                               <tr>
                                                 
                                                   <th>Value</th>
                                                   <th></th>
                                               </tr>
                                               </thead>
                                               <tbody>
                                               {variants.map((variant, index) => (
                                                 <tr key={index}>
                                                    
                                                     <td><input type="text" className="form-control" value={variant.value} placeholder="Red,XL" onChange={(e) => {
                                                         const updatedVariants = [...variants];
                                                         updatedVariants[index].value = e.target.value;
                                                         setVariants(updatedVariants);
                                                     }} /></td>
                                                       <td>
                                                        {index === 0 ? (
                                                         <a  class="btn btn-primary btn-sm" onClick={handleAddVariant}>
                                                            <i className="bx bx-plus"></i>
                                                         </a> 
                                                        ) : (
                                                            <a  class="btn btn-primary btn-sm"  onClick={() => handleRemoveVariant(index)}>
                                                            <i className="bx bx-minus"></i>
                                                         </a> 
                                                        )}
                                                      </td>
                                                     
                                                 </tr>
                                               ))}
                                               </tbody>
                                           </table>
                                          
                                     </div>
                                   </div>
                               </div>
                              
                           </div>
                       </div>
                       <div className="col-12 col-md-6">
                           <div className="row">
                           <div className="col-12 col-md-12 mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                        <div className='row'>
                                        <div class="col-12 col-md-6">
                                        {imagePreview && (
                                             <img src={imagePreview} alt="Product Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                           )}
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <h4>Product Image <span className="text-danger">*</span></h4>
                                                <input type="file" className="form-control" id="image" accept="image/*" onChange={handleImageChange} required />
                                            </div>  
                                        </div>
                                     </div>
                                   </div>
                               </div>
                               <div className="col-12 col-md-12 mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                       <h4>Shipping & Delivery</h4>
                                       <div className="row">
                                         <div className="col">
                                           <label htmlFor="itemWeight" className="form-label">Item Weight:</label>
                                           <input type="number" className="form-control" id="itemWeight" value={itemWeight} onChange={(e) => setItemWeight(e.target.value)} placeholder='Weight'/>
                                         </div>
                                         <div className="col">
                                           <label htmlFor="packageHeight" className="form-label">Package Height:</label>
                                           <input type="number" className="form-control" id="packageHeight" value={packageHeight} onChange={(e) => setPackageHeight(e.target.value)} placeholder='Height'/>
                                         </div>
                                         <div className="col">
                                           <label htmlFor="packageWidth" className="form-label">Package Width:</label>
                                           <input type="number" className="form-control" id="packageWidth" value={packageWidth} onChange={(e) => setPackageWidth(e.target.value)} placeholder='Width'/>
                                         </div>
                                         <div className="col">
                                           <label htmlFor="packageLength" className="form-label">Package Length:</label>
                                           <input type="number" className="form-control" id="packageLength" value={packageLength} onChange={(e) => setPackageLength(e.target.value)} placeholder='Length'/>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                               </div>
                               <div className="col-12 col-md-12 mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                       <h4>Pricing</h4>
                                       <label htmlFor="itemPrice" className="form-label">Item Price: <span className="text-danger">*</span></label>
                                       <input type="number" className="form-control" id="itemPrice" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} placeholder='Price' required />
                                     </div>
                                   </div>
                               </div>
                               <div className="col-12 col-md-12 mb-3">
                                   <div className="card">
                                     <div className="card-body">
                                       <h4>Thresholds</h4>
                                       <label htmlFor="highStockThreshold" className="form-label">High Stock Threshold: <span className="text-danger">*</span></label>
                                       <input type="number" className="form-control" id="highStockThreshold" value={highStockThreshold} onChange={(e) => setHighStockThreshold(e.target.value)} placeholder='Stock' required />
                                       <label htmlFor="lowStockThreshold" className="form-label mt-2">Low Stock Threshold: <span className="text-danger">*</span></label>
                                       <input type="number" className="form-control" id="lowStockThreshold" value={lowStockThreshold} onChange={(e) => setLowStockThreshold(e.target.value)} placeholder='Stock' required />
                                     </div>
                                   </div>
                               </div>
                               <div class="text-end">
                        <a href="" class="btn btn-primary" onClick={handleSubmit}>Submit</a>
                    </div>
                               {/* <div className="col-12 col-md-12 mb-3">
                                   <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                               </div> */}
                           </div>
                       </div>
                   </div>
                 </div>
               </div>
           </section>
     </div>
    </div>
  )
}

export default Add_product
