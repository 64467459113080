import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, FileDown, Truck } from 'lucide-react';

const Stock_report = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const[stateproduct , setproduct]= React.useState([])
    const[statewarehouse , setStatewarehouse]= React.useState([])

    const[state, setState] = React.useState({from_date:"" , to_date:"" , product_id:"" , warehouse_id:"" , mode:0})
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    const[reportstate, setreport] = React.useState([])
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000
  })

  const handleChange=(e)=>{
    setState({...state, [e.target.name]:e.target.value})
  }

  React.useEffect(()=>{
    stock_entries_ofcustomers(0,true)
    fetchWarehouse()
    all_products()
  },[])

  const stock_entries_ofcustomers = (index=0,onLoad)=>{
       
    let full_api = config.apiUrl + `/user/stock_reports_of_customers`;
    let sendData = {warehouse_id:state.warehouse_id , product_id:state.product_id , mode:state.mode , from_date:state.from_date ,to_date:state.to_date , limit:otherStates.rowsPerPage,indexValue:index} ;
 console.log("sendData == " , sendData)
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        if( onLoad) {
            setOtherState({...otherStates,total_count:res.data.count ,  page:index})   
            setreport(res.data.output)      

}
        // setstockentrystate(res.data.output)
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
   }

   const handlePageChange = (event,newPage) =>{
    setOtherState({...otherStates,page:newPage})
    // console.log("newPage ", newPage);
    // getCountryList(newPage);
    stock_entries_ofcustomers(newPage,true)
}
const handleChangeRowsPerPage = (event , newPage) => {
    // console.log("event ", event.target.value);
    setOtherState({ ...otherStates, rowsPerPage: event.target.value })
     
}

    const all_products = ()=>{
    let full_api = config.apiUrl + `/user/product_list`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setproduct(res.data.output)
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  
}
const fetchWarehouse = (e)=>{
       
    let full_api = config.apiUrl + `/user/fetch_warehouse`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setStatewarehouse(res.data.output)
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
   }

  return (
    <div>
      
    
    <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section" >
         
          <Header/>

            <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                            <i data-lucide="corner-up-left"></i>
                            </a>
                        </div>
                        <div class="">
                            <h2>Stock Report </h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-end btns">
                <a href="/customer/add_product" class="btn btn-primary">Add Product</a>
                </div>
            </div>


            <section>
                <div class="row mb-3">
                    <div class="col-12 col-md-12">
                        <div class="card border-0 shadow-sm">
                          <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">From Date:</label>
                                    <input type="date" class="form-control" id="text" placeholder="Enter date" name="from_date" onChange={(e)=>handleChange(e)}/>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">To Date:</label>
                                    <input type="date" class="form-control" id="text" placeholder="Enter date" name="to_date" onChange={(e)=>handleChange(e)}/>
                                </div>
                                <div class="col-12 col-md-4 mb-3">
                                    <label for="email" class="form-label">Products:</label>
                                    <select class="form-select" name="product_id" onChange={(e)=>handleChange(e)}>
                                    <option>Select</option>
                {stateproduct.map((sub)=>(
                    <option value={sub.product_id}>{sub.product_name}</option>
                ))}
                                    </select>
                                </div>
                                <div class="col-12 col-md-4 mb-3">
                                    <label for="email" class="form-label">Warehouse:</label>
                                    <select class="form-select" name="warehouse_id" onChange={(e)=>handleChange(e)}>
                                    <option>Select</option>
                {statewarehouse.map((sub)=>(
                <option value={sub.address_id}>{sub.warehouse_name}</option>))}
                                    </select>
                                </div>
                                <div class="col-12 col-md-4 mb-3">
                                    <label for="email" class="form-label">Mode:</label>
                                    <select class="form-select" name="mode" onChange={(e)=>handleChange(e)}>
                                      <option value="0">All Mode</option>
                                      <option value="1">In Bound</option>
                                      <option value="2">Out Bound</option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-3 mb-3">
                                    <div class="d-grid">
                                      <a onClick={(e)=>stock_entries_ofcustomers(0,true)} class="btn btn-primary btn-block">Get Report</a>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section>
            <div class="content-wrapper-data">
                    <div class="card border-0">
                    <div class="card-body">
                            <div class="table-data table-responsive">
                                <table class="table table-hover table-responsive">
                                    <thead>
                                    <tr class="table-light">
                                        <th>Date</th>
                                        <th>SKU #</th>
                                        <th>Product Name</th>
                                        <th>Warehouse </th>
                                        <th>Customer Details</th>
                                        <th>Vendor Added Units</th>
                                        <th>Approved Units</th>
                                        <th>Mode</th>
                                        <th>Status</th>
                                        <th>Remark</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {reportstate.map((sub)=>(
                                        <tr class="ship-now-container">
                                            <td>
                                                <h6><Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.created*1000)}
             </Moment></h6>
                                            </td>
                                            <td>
                                                <h3><a href={`/customer/product_detail/${sub.product_id}`}>#{sub.product_detail.sku_code}</a></h3>
                                            </td>
                                            <td>
                                                <h4><a href={`/customer/product_detail/${sub.product_id}`}>{sub.product_detail.product_name}</a></h4>
                                                {sub.product_detail.variants.map((sub)=>( <h6>{sub.value}</h6>))}
                                            </td>
                                            <td>
                                            {sub.warehouse_name}
                                            </td>
                                            <td>
                                                <h4><a href="">{userData.full_name}</a></h4>
                                                <small>Account #{userData.customer_id}</small>
                                            </td>
                                            <td>
                                                <h4>{sub.vendor_added_units}</h4>
                                            </td>
                                            <td>
                                                <h4>{sub.approved_units}</h4>
                                            </td>
                                           
                                            <td>
                                               {sub.mode==1? <small><span class="badge rounded-pill bg-success fw-400">In Bound</span></small>:  <small><span class="badge rounded-pill bg-danger fw-400">Out Bound</span></small>}
                                            </td>
                                            <td>
                                                {sub.status==0? <small><span class="badge rounded-pill bg-warning fw-400">Pending</span></small>:sub.status==1? <small><span class="badge rounded-pill bg-success fw-400">Confirmed</span></small>: <small><span class="badge rounded-pill bg-danger fw-400">Rejected</span></small>}
                                               
                                            </td>
                                            <td>#{sub.remark}</td>
                                        </tr>
                                        ))}
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <TablePagination
                component="div"
                rowsPerPageOptions={[5,10,15,20,25,50]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />

           

                

        </div>
        {/* <!-- Main content area END --> */}
  
{/* <?php include 'footer.php';?> */}

    </div>
  )
}

export default Stock_report
