import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import { useNetworkState } from 'react-use';
const Bulk_orders_log_detail = () => {
    const{log_id} = useParams() 
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
const[state,setState]= React.useState([])
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    const shipment_list=(status)=>{
        let dataToSend = {log_id : log_id
    };
   
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            setState(res.data.output)
            // setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    React.useEffect(()=>{
        shipment_list()
    },[])
  return (
    <div >
   <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section" >
         
          <Header/>
        
{state.map((sub)=>(
        <section class="mb-3 pt-4">
          <div class="">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <h3><a href={`/customer/bulk_orders_log/${sub.log_id}`}><i class="fa fa-chevron-left" aria-hidden="true"></i></a> Bulk Orders Log</h3>
                <p>ID: {sub.log_id}  <small class="text-muted"><i class="fa fa-clock-o" aria-hidden="true"></i> {sub.time} {sub.date}</small></p>
              </div>
              
              <div class="col-12 col-md text-end">
                <h3>{sub.uploaded_records}<small class="text-muted">/{sub.total_records}</small></h3>
                <p><span class="badge rounded-pill bg-success fw-400">{sub.status==0?"Running":"Completed"}</span></p>
              </div>

            </div> 
          </div>
        </section>
))}


        <section class="mb-3">
          <div class="">
            <div class="table-responsive">
                <table class="table table_box">
                    <thead  class="">
                        <tr class="brd">
                        <th>Order Number</th>
                        <th>Status</th>
                        <th>Error Log</th>
                    </tr>
                    </thead>
                    <tbody>
                        {state.map((sub)=>(
                            sub.log.length>0?
                            sub.log.map((item)=>(
                        <tr class="brd">
                            <td>
                                <h5><a href="#"></a>{item.order_number}</h5>
                            </td>
                            <td>
                               {item.status==1? <h2><span class="badge rounded-pill bg-success fw-400">Completed</span></h2>:item.status==0? <h2><span class="badge rounded-pill bg-info fw-400">Running</span></h2>:<h2><span class="badge rounded-pill bg-danger fw-400">Error</span></h2>}
                            </td>
                            <td>
                                <h5>{item.reason}</h5>
                            </td>
                        </tr>
                        )):""
                        ))}
                        
                    </tbody>
                </table>
            </div>     
          </div>
        </section>
        
  

    </div>
    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Bulk_orders_log_detail
