import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, CornerUpLeftIcon, FileDown, Truck } from 'lucide-react';
import Chart from 'chart.js/auto';
const Warehouse_inventory = () => {
    const [chartData, setChartData] = React.useState({});
    console.log("chartData == " , chartData)

    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [modalState, setModalState] = React.useState({ show: false });
    
    const[state , setState]= React.useState([])
    const[statewarehouse , setStatewarehouse]= React.useState([])

    const[statevariants , setStatevariants]= React.useState([])

    const[productstate , setProductState]= React.useState({product_id:""})
    const[warehouseset , setwarehousesset]= React.useState({warehouse_id:""})
    const[remarkstate , setremarkstate]= React.useState({remark:""})

    const [variantQuantities, setVariantQuantities] = React.useState([]);
    const [stockentrystate, setstockentrystate] = React.useState([]);
    const [stockData, setStockData] = React.useState({
        overstock: 0,
        understock: 0,
        instock: 0,
      });
    
    
    console.log("variantQuantities == " , variantQuantities)

React.useEffect(()=>{
    fetchWarehouse()
    stock_entries_ofcustomers()
},[])
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    const clickAddStock = ()=>{
        setModalState({show:true})
      
            let full_api = config.apiUrl + `/user/product_list`;
            let sendData = {};
          
            axios.post(full_api, sendData, { headers: config.headers }).then(res => {
                setState(res.data.output)
            }).catch((e) => {
              // toast.configure()
               //toast.error("Some thing went wrong")
              // console.log("----error:   ", e);
            })
          
    }

    const handleSelect = (e)=>{
     setProductState({...productstate , product_id:e.target.value})
     let full_api = config.apiUrl + `/user/product_list_with_id`;
     let sendData = {product_id:e.target.value};
   
     axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        setStatevariants(res.data.output)
     }).catch((e) => {
       // toast.configure()
        //toast.error("Some thing went wrong")
       // console.log("----error:   ", e);
     })
    }

    const fetchWarehouse = (e)=>{
       
        let full_api = config.apiUrl + `/user/fetch_warehouse`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            setStatewarehouse(res.data.output)
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
       }
    
       const selectWarehouse =(e)=>{
        setwarehousesset({...warehouseset , warehouse_id:e.target.value})
       }
       const selectRemark =(e)=>{
        setremarkstate({...remarkstate , remark:e.target.value})
       }
       

       const handleQuantityChange = (variantId, quantity, variantName) => {
        // Find the index of the variant in the variantQuantities array
        const variantIndex = variantQuantities.findIndex(v => v.id ==variantId);
        console.log("variantIndex ==  , " , variantIndex)

      
        if (variantIndex != -1) {
          // Variant already exists in the array, update its quantity
          const updatedVariant = { ...variantQuantities[variantIndex], quantity };
          const updatedVariantQuantities = [...variantQuantities];
          updatedVariantQuantities[variantIndex] = updatedVariant;
          setVariantQuantities(updatedVariantQuantities);
        } else {
          // Variant does not exist in the array, add it with its quantity
          const newVariant = { id: variantId, name: variantName, quantity };
          setVariantQuantities(prevQuantities => [...prevQuantities, newVariant]);
        }
      };
      
    
      const handleAddStock = () => {
        // Construct data to send to backend
        if(warehouseset.warehouse_id!=undefined && warehouseset.warehouse_id!="" && productstate.product_id!=undefined && productstate.product_id!="" && remarkstate.remark!=undefined && remarkstate.remark!="" && variantQuantities.length>0 ){
        const data = {
          warehouse_id: warehouseset.warehouse_id,
          product_id: productstate.product_id,
          variant_quantities:variantQuantities,
          remark: remarkstate.remark, // Get the remark value from the input field
        };
        console.log("data=== " , data)
    
        // Send data to backend API
        axios.post(`${config.apiUrl}/user/add_stock`, data, { headers: config.headers })
          .then(response => {
            // Handle success responseif
            if(response.data.status==200){
            console.log('Stock added successfully:', response.data);
            Swal.fire({
                icon: 'success',
                title: 'Success...',
                text: response.data.message,
              });
              setModalState({show:false})
              stock_entries_ofcustomers()
            }
            else if(response.data.status==404){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                  });
              setModalState({show:false})
              stock_entries_ofcustomers()

            }
            else if(response.data.status==500){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message,
                  });
              setModalState({show:false})
              stock_entries_ofcustomers()

            }
          })
          .catch(error => {
            // Handle error response
            console.error('Error adding stock:', error);
          });
        }
        else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required!',
              });
        }
      };
      const stock_entries_ofcustomers = (e)=>{
       
        let full_api = config.apiUrl + `/user/stock_entries_of_customers`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            setstockentrystate(res.data.output)
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
       }

       React.useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(config.apiUrl +'/user/bound_entries' , {headers:config.headers}); // Assuming your backend API is hosted at '/api/entries'
            const { labels, inboundItems, outboundItems } = response.data;
    
            // Update chart data
            setChartData({
              labels,
              datasets: [
                {
                  label: 'Inbound Items',
                  data: inboundItems,
                  borderColor: '#FF6D00',
                  fill: false
                },
                {
                  label: 'Outbound Items',
                  data: outboundItems,
                  borderColor: '#082D79',
                  fill: false
                },
               
              ]
            });
          } catch (error) {
            console.error('Error fetching chart data:', error);
          }
        };
    
        fetchData();
      }, []); // Fetch data only once on component mount
    
      React.useEffect(() => {
        // Create the line chart
        if (chartData.labels && chartData.labels.length) {
            var ctx = document.getElementById('dailyCapacityChart').getContext('2d');

            // If dailyCapacityChart already exists, destroy it
          
            // Create the line chart
            var dailyCapacityChart = new Chart(ctx, {
                type: 'line',
                data: chartData,
                options: {
                    responsive: true,
                    title: {
                        display: true,
                        text: 'Daily Capacity Change (Last Week)'
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
            return () => {
                dailyCapacityChart.destroy();
              };
        }
      }, [chartData]); 

     
       
        React.useEffect(() => {
          // Fetch your product data from the backend
          // Replace this with your actual API call
          const fetchProductData = async () => {
            try {
                let full_api = config.apiUrl + `/user/product_list`;
                let sendData = {};
              
                axios.post(full_api, sendData, { headers: config.headers }).then(res => {
                    let data = res.data.output
                    console.log("data ==" , data)
                       // Analyze stock data
                       let overstockCount = 0;
                       let understockCount = 0;
                       let instockCount = 0;
               
                       data.forEach((product) => {
                         product.stock.forEach((stockItem) => {
                           stockItem.variant.forEach((variant) => {
                             const stock = variant.stock;
                             const minThreshold = product.min_threshold;
                             const maxThreshold = product.max_threshold;
               
                             if (stock < minThreshold) {
                               understockCount++;
                             } else if (stock > maxThreshold) {
                               overstockCount++;
                             } else {
                               instockCount++;
                             }
                           });
                         });
                       });
               
                       // Update state with stock data
                       setStockData({
                         overstock: overstockCount,
                         understock: understockCount,
                         instock: instockCount,
                       });
                }).catch((e) => {
                  // toast.configure()
                   //toast.error("Some thing went wrong")
                  // console.log("----error:   ", e);
                })
              // Replace this with your actual API endpoint
             
            
          
            } catch (error) {
              console.error('Error fetching product data:', error);
            }
          };
      
          fetchProductData();
        }, []); // Fetch data on component mount
      
        React.useEffect(() => {
          // Create chart after stockData is updated
          const ctx = document.getElementById('stockChart').getContext('2d');
          const stockChart = new Chart(ctx, {
            type: 'pie',
            data: {
              labels: ['Overstock Items', 'Understock Items', 'In Stock Items'],
              datasets: [{
                data: [stockData.overstock, stockData.understock, stockData.instock],
                backgroundColor: [
                  '#3C096C', // Red for Overstock
                  '#FF6D00', // Blue for Understock
                  '#9D4EDD' // Green for In Stock
                ],
                borderWidth: 1
              }]
            },
            options: {
              responsive: true,
              title: {
                display: true,
                text: 'Stock Status'
              }
            }
          });
      
          // Cleanup function to destroy chart when component unmounts
          return () => stockChart.destroy();
        }, [stockData]); 
   
  return (
    <div>
        <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section" >
         
          <Header/>
     
       
       
          
            

            <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                          <CornerUpLeftIcon/>
                            </a>
                        </div>
                        <div class="">
                            <h2>ALL WAREHOUSES</h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-end btns">
                    <a onClick={() => clickAddStock()} class="btn btn-primary btn-sm me-2" >Add Stock</a>
                    <div class="btn-group" role="group">
                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        All Warhouse
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            {statewarehouse.map((sub)=>(
                        <li><a class="dropdown-item" href="#">{sub.warehouse_name}</a></li>))}
                       
                        </ul>
                    </div>
                </div>
            </div>
            
           <div class="row mb-3 mt-4 warehouse_invtntry">
               <div class="col-12 col-md-4">
                <div class="card border-0 shadow-sm">
                  <div class="card-body">
                    <h4>Over & Under Stock</h4>
                    {/* <small>Total Stock: <strong>2939</strong></small> */}
                    <div class="mt-3 text-center">
                        <canvas id="stockChart" width="100" height="100"></canvas>
                    </div>
                  </div>
                </div>
               </div>
               <div class="col-12 col-md-8">
                    <div class="card border-0 shadow-sm">
                      <div class="card-body">
                        <h4>Weekly Capacity Change</h4>
                        {/* <small>Total Stock: <strong>2939</strong></small> */}
                        <canvas id="dailyCapacityChart" width="100" height="52"></canvas>
                      </div>
                    </div>
               </div>
           </div>


           <section class="pb-3">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#home">Recent Stock Entries</a>
                </li>
                {/* <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#menu1">Products</a>
                </li> */}
            </ul>

           
            <div class="tab-content">
            <div class="tab-pane container active" id="home">
                <div class="content-wrapper-data">
                    <div class="card border-0">
                    <div class="card-body">
                            <div class="table-data table-responsive">
                                <table class="table table-hover table-responsive">
                                    <thead>
                                    <tr class="table-light">
                                        <th>Date</th>
                                        <th>SKU #</th>
                                        <th>Product Name</th>
                                        <th>Warehouse </th>
                                        <th>Customer Details</th>
                                        <th>Vendor Added Units</th>
                                        <th>Approved Units</th>
                                       
                                        <th>Mode</th>
                                        <th>Status</th>
                                        <th>Remark</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {stockentrystate.map((sub)=>(
                                        <tr class="ship-now-container">
                                            <td>
                                                <h6><Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.created*1000)}
             </Moment></h6>
                                            </td>
                                            <td>
                                                <h3><a href={`/customer/product_detail/${sub.product_id}`}>#{sub.product_detail.sku_code}</a></h3>
                                            </td>
                                            <td>
                                                <h4><a href={`/customer/product_detail/${sub.product_id}`}>{sub.product_detail.product_name}</a></h4>
                                                {sub.variants.map((sub)=>( <h6>{sub.value}</h6>))}
                                            </td>
                                            <td>
                                            {sub.warehouse_name}
                                            </td>
                                            <td>
                                                <h4><a href="">{userData.full_name}</a></h4>
                                                <small>Account #{userData.customer_id}</small>
                                            </td>
                                            <td>
                                                <h4>{sub.vendor_added_units}</h4>
                                            </td>
                                            <td>
                                                <h4>{sub.approved_units}</h4>
                                            </td>
                                           
                                            <td>
                                               {sub.mode==1? <small><span class="badge rounded-pill bg-success fw-400">In Bound</span></small>:  <small><span class="badge rounded-pill bg-danger fw-400">Out Bound</span></small>}
                                            </td>
                                            <td>
                                                {sub.status==0? <small><span class="badge rounded-pill bg-warning fw-400">Pending</span></small>:sub.status==1? <small><span class="badge rounded-pill bg-success fw-400">Confirmed</span></small>: <small><span class="badge rounded-pill bg-danger fw-400">Rejected</span></small>}
                                               
                                            </td>
                                            <td>#{sub.remark}</td>
                                        </tr>
                                        ))}
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane container fade" id="menu1">
            <div class="content-wrapper-data">
                    <div class="card border-0">
                    <div class="card-body">
                            <div class="table-data table-responsive">
                                <table class="table table-hover table-responsive">
                                    <thead>
                                    <tr class="table-light">
                                        <th>SKU #</th>
                                        <th>Product Name</th>
                                        <th>Warehouse </th>
                                        <th>Customer Details</th>
                                        <th>Total Units</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="ship-now-container">
                                            <td>
                                                <h3><a href="product_detail.php">#1233494</a></h3>
                                            </td>
                                            <td>
                                                <h4><a href="product_detail.php">Wide neck POLO T-shirt</a></h4>
                                                <h6>Size: XL</h6>
                                                <h6>Color: RED</h6>
                                            </td>
                                            <td>
                                            38827-00 / Main Warehouse Gurugram
                                            </td>
                                            <td>
                                                <h4><a href="">{userData.full_name}</a></h4>
                                                <small>Account #{userData.customer_id}</small>
                                            </td>
                                            <td>
                                                <h4>800</h4>
                                                <small class="text-info">Overstock</small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
           </section>

            
                    <section>
                    <div class="offcanvas offcanvas-end " id="order_details">
                        <div class="offcanvas-header">
                            <div class="offcanvas-title">
                                <div class="row mb-2">
                                    <div class="col-12 col-md">
                                     <h2><small>Order #</small>298239842348</h2>
                                    </div>
                                    <div class="col-12 col-md-2 text-end">
                                        
                                    </div>
                                    <div class="col-12 col-md-4 text-end btns">
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Edit Order"><i data-lucide="file-edit"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Clone Order"><i data-lucide="copy"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Cancel Order"><i data-lucide="ban"></i></a>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
                        </div>
                        <div class="offcanvas-body">


                            <div class="">
                                <div class="row mt-3 order_box_cont">
                                    <div class="col-12 col-md-12 ">
                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                                <div class="d-flex ">
                                                    <div class="border-end pe-3">
                                                    <i data-lucide="shopping-bag"></i>
                                                    </div>
                                                    <div class="ps-3 ">
                                                        <h6>Order Details</h6>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Order created on channel</small>
                                                        <p>11 Jul 2023 12:00 AM</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Order created on system</small>
                                                        <p>11 Jul 2023 03:41 PM</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Channel</small>
                                                        <p>IndoChina Store - Woocommerce</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Shipment value</small>
                                                        <p>₹ 2000 <span class="badge rounded-pill bg-warning">Prepaid</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                        <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="package"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Shipment Details</h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-12 col-md">
                                                    <h6>Ship From:</h6>
                                                        <small class="text-muted">Sender Name:</small>
                                                        <p>Rahul Gandi</p>
                                                        <small class="text-muted">Sender Address:</small>
                                                        <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                                        <small class="text-muted">Sender Email:</small>
                                                        <p>rahulgandi@gmail.com</p>
                                                        <small class="text-muted">Sender Mobile:</small>
                                                        <p>123456789</p>
                                                </div>
                                                <div class="col-12 col-md">
                                                    <h6>Ship To:</h6>
                                                        <small class="text-muted">Receiver Name:</small>
                                                        <p>Rahul Gandi</p>
                                                        <small class="text-muted">Receiver Address:</small>
                                                        <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                                        <small class="text-muted">Receiver Email:</small>
                                                        <p>rahulgandi@gmail.com</p>
                                                        <small class="text-muted">Receiver Mobile:</small>
                                                        <p>123456789</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>



                                        <div class="card border-0  mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="package-open"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Package Details</h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Applicable Weight (in Kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dead Weight (in Kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Volumetric Weight (in kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dimensions (in cm)</small>
                                                    <p>1.000 x 1.000 x 1.000</p>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <small class="text-muted">Number of Boxes</small>
                                                    <p>1</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="shopping-basket"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Product Details</h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="table-responsive ">
                                                <table class="table table-light">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>HSN</th>
                                                        <th>SKU</th>
                                                        <th>Qty</th>
                                                        <th>Unit price</th>
                                                        <th>Discount</th>
                                                        <th>Tax</th>
                                                        <th>Total</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Samsung mobile Transparent Covers - Samsung-A50</td>
                                                        <td>1689070274414</td>
                                                        <td>samsung-A50</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>200.00</td>
                                                        <td>100.00</td>
                                                        <td>10.00</td>
                                                        <td>100.00</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>

                                        
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={modalState.show} onHide={() => setModalState({ show: false })} >
 <Modal.Header closeButton>
                     <Modal.Title>
                     <h6 class="modal-title">Add Stock</h6>
                     </Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
                 <div class="modal-body">
        <div class="row">
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Select Product:</label>
              <select class="form-select" onChange={(e)=>handleSelect(e)}>
                <option>Select</option>
                {state.map((sub)=>(
                    <option value={sub.product_id}>{sub.product_name}</option>
                ))}
              </select>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Select Warehouse:</label>
              <select class="form-select" onChange={(e)=>selectWarehouse(e)}>
                <option>Select</option>
                {statewarehouse.map((sub)=>(
                <option value={sub.address_id}>{sub.warehouse_name}</option>))}
               
              </select>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Enter Item Quantity:</label>
              <table class="table table-bordered table-responsive">
                  <thead>
                    <tr>
                      <th>Variants</th>
                      <th>Item Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statevariants.map((sub)=>(
                    <tr>
                      <td>{sub.value}</td>
                      <td><input type="text" class="form-control" id="text" placeholder="Enter details name" onChange={(e) => handleQuantityChange(sub.variant_id, e.target.value, sub.value)} /></td>
                    </tr>
                    ))}
                  
                  </tbody>
                </table>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Remark:</label>
              <input type="text" class="form-control" id="text" placeholder="Remark for store keeper" onChange={(e)=>selectRemark(e)}/>
            </div>
        </div>
        <small>Added stock will be verified by the store keeper and active only after the approval. </small>
      </div>
      <div class="modal-footer">
        <a href="#" class="btn btn-primary" onClick={(e)=>handleAddStock(e)}>Add Stock</a>
        <button type="button" class="btn btn-danger" onClick={() => setModalState({ show: false })}>Close</button>
      </div>
    
                </Modal.Body>
               
            </Modal>


                {/* <div class="modal" id="add_stock">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Stock</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Select Product:</label>
              <select class="form-select">
                <option>Select</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Select Warehouse:</label>
              <select class="form-select">
                <option>Select</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Enter Item Quantity:</label>
              <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Variants</th>
                      <th>Item Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>XL, Red</td>
                      <td><input type="text" class="form-control" id="text" placeholder="Enter details name"/></td>
                    </tr>
                    <tr>
                      <td>XL, Black</td>
                      <td><input type="text" class="form-control" id="text" placeholder="Enter details name"/></td>
                    </tr>
                    <tr>
                      <td>L, Red</td>
                      <td><input type="text" class="form-control" id="text" placeholder="Enter details name"/></td>
                    </tr>
                    <tr>
                      <td>L, Black</td>
                      <td><input type="text" class="form-control" id="text" placeholder="Enter details name"/></td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div class="col-12 col-md-12 mb-3">
              <label for="email" class="form-label">Remark:</label>
              <input type="text" class="form-control" id="text" placeholder="Remark for store keeper"/>
            </div>
        </div>
        <small>Added stock will be verified by the store keeper and active only after the approval. </small>
      </div>
      <div class="modal-footer">
        <a href="#" class="btn btn-primary">Add Stock</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>    */}

       
        {/* <!-- Main content area END --> */}
    </div> 
    </div>
  )
}

export default Warehouse_inventory
